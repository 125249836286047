import React from 'react'
import './Contact.css'

function Contact() {
  return (
    <div className="contact-container">
        <p className="headline">William Vindum Husum</p>
        <p><a href="tel:42779316">42 77 93 16</a></p>
        <p><a href="mailto:husum44@gmail.com">husum44@gmail.com</a></p>
        <p><a href="https://www.linkedin.com/in/williamhusum/">LinkedIn</a></p>
    </div>
  )
}

export default Contact