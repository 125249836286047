import React from "react";
import "./Frontpage.css";

function Frontpage() {
  return (
    <div className="frontpage-area">
      <h1 className="headline">WILLIAM VINDUM HUSUM</h1>
    </div>
  );
}

export default Frontpage;
