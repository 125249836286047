import React from 'react'
import './Menu.css';

function Menu() {
  return (
    <div>
        <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/portfolio">Portfolio</a></li>
            <li><a href="/contact">Contact</a></li>

</ul>
    </div>
  )
}

export default Menu