import "./App.css";
import { Routes, Route } from "react-router-dom";
import Frontpage from "./components/Frontpage/Frontpage";
import Menu from "./components/Menu/Menu";
import Contact from "./components/Contact/Contact";
import Portfolio from "./components/Portfolio/Portfolio";

function App() {
  return (
    <div className="App">
      <Menu />
      <Routes>
        <Route path="/" element={<Frontpage />}></Route>
        <Route path="/contact" element={<Contact />}></Route>
        <Route path="/portfolio" element={<Portfolio />}></Route>
      </Routes>
    </div>
  );
}

export default App;
